import * as React from "react"
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SiteContainer from "../../components/site-container";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const DocsPage = (location) => {
    return (
        <SiteContainer>
            <Helmet>
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>
            <Row>
                <Col>
                    <Link to={"/docs/introduction"} style={{ textDecoration: 'none' }}>
                        <Card>
                            <Card.Body>
                                <Card.Title>System Reference</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Understand How Firstparty Works</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={"/docs/api/reference"} style={{ textDecoration: 'none' }}>
                        <Card>
                            <Card.Body>
                                <Card.Title>REST API Reference</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Configure Firstparty with HTTP Requests</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </SiteContainer>
    )
}

export default DocsPage